import React from 'react';
import PropTypes from 'prop-types';
import { HeadingF, ProductContent } from 'DesignSystem/Typography';
import { PrimaryButton } from 'DesignSystem/Buttons';
import styles from './SecondaryCTAColumn.module.scss';

const SecondaryCTAColumn = ({
  ctaOnClick,
  ctaText,
  dataTestid,
  heading,
  info,
}) => (
  <>
    <HeadingF>{heading}</HeadingF>
    <ProductContent className={styles['secondary-cta-info']}>
      {info}
    </ProductContent>
    <PrimaryButton
      dataTestid={dataTestid}
      onClick={ctaOnClick}
      variation="alternative"
    >
      {ctaText}
    </PrimaryButton>
  </>
);

SecondaryCTAColumn.propTypes = {
  ctaOnClick: PropTypes.func.isRequired,
  ctaText: PropTypes.string.isRequired,
  dataTestid: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SecondaryCTAColumn;
