import React from 'react';
import { HeadingE, ProductContent } from 'DesignSystem/Typography';
import FormLinkColumn, { LinksObject } from './FormLinkColumn';
import styles from './FormSection.module.scss';

export interface FormSectionProps {
  type: string;
  description: string;
  links: LinksObject[];
  fireTracking: (action: string) => void;
}
const FormSection = ({
  description,
  fireTracking,
  links,
  type,
}: FormSectionProps): JSX.Element => {
  const halfIndex = Math.ceil(links.length / 2);
  const firstColumn = links.slice(0, halfIndex);
  const secondColumn = links.slice(halfIndex);

  return (
    <div className={styles['forms-container']} id="legalForms">
      <HeadingE className={styles.title}>{type}</HeadingE>
      <div className={styles['content-container']}>
        <ProductContent className={styles['forms-description']}>
          {description}
        </ProductContent>
        <div className={styles['links-container']}>
          <FormLinkColumn fireTracking={fireTracking} links={firstColumn} />
          <FormLinkColumn fireTracking={fireTracking} links={secondColumn} />
        </div>
      </div>
    </div>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FormSection;
