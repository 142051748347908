import { isomorphicRedirect } from 'IsomorphicUtils';
import { NextPageContext } from 'next';
import { consoleError } from 'Utils/consoleError';
import RedirectError from './RedirectError';

/**
 * Wraps `getInitialProps` in a function that catches redirect errors
 * and handles them appropriately.
 *
 * @param getInitialProps A `getInitialProps` function to wrap.
 */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default function handleRedirects<
  Context extends NextPageContext,
  InitialProps
>(getInitialProps: (ctx: Context) => Promise<InitialProps>) {
  return async (ctx: Context): Promise<InitialProps | undefined> => {
    let result: InitialProps | undefined;
    const { res } = ctx;
    try {
      result = await getInitialProps(ctx);
    } catch (e) {
      if (e instanceof RedirectError) {
        const { redirectPath } = e;
        if (redirectPath) {
          isomorphicRedirect(redirectPath, res);
        } else {
          consoleError('Redirect error was thrown without a redirectPath.');
        }
      } else {
        // Throw all other errors. These should probably be fixed.
        throw e;
      }
    }
    return result;
  };
}
