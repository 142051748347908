import React from 'react';
import { ProductContent } from 'DesignSystem/Typography';
import styles from './FormLinkColumn.module.scss';

export interface LinksObject {
  title: string;
  url: string;
}

export interface FormLinkColumnProps {
  fireTracking: (action: string) => void;
  links: LinksObject[];
}
const FormLinkColumn = ({
  fireTracking,
  links,
}: FormLinkColumnProps): JSX.Element => {
  return (
    <div className={styles.column}>
      {links.map((listItem) => (
        <ProductContent
          key={listItem.title}
          bold
          className={styles['form-link']}
          variation="small"
        >
          <a href={listItem.url} onClick={() => fireTracking(listItem.title)}>
            {listItem.title}&nbsp;›
          </a>
        </ProductContent>
      ))}
    </div>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FormLinkColumn;
