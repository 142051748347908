export const REVIEWS = [
  {
    name: 'Bradley W.',
    position: 'Marketing Director',
    ratingSource: 'star-rating-four',
    review: `"A ridiculously simple legal form creation website"`,
    source: 'g2.com',
  },
  {
    name: 'Tyson N.',
    position: 'Account Manager',
    ratingSource: 'star-rating-four-and-half',
    review: `"A document creation and editing platform that's as intuitive as they get"`,
    source: 'g2.com',
  },
  {
    name: 'Olivia N.',
    position: 'Freelance Writer',
    ratingSource: 'star-rating-five',
    review: `"Great product. It's easy to use. Makes my job a whole lot easier."`,
    source: 'g2.com',
  },
];

export const FORMS = [
  {
    description:
      'FormSwift offers many legal documents for personal use. Personal forms typically do not involve more than two or three parties. These forms may be used to secure a transaction involving products or services, to set up a contract to protect a party from dispute, or to make a formal legal request.',
    links: [
      { title: 'Bill of Sale', url: '/bill-of-sale' },
      { title: 'Affidavit', url: '/affidavit' },
      { title: 'Vehicle Bill of Sale', url: '/vehicle-bill-of-sale' },
      { title: 'Power of Attorney', url: '/power-of-attorney' },
      { title: 'Letter of Recommendation', url: '/letter-of-recommendation' },
      { title: 'Guardianship Forms', url: '/guardianship-forms' },
      { title: 'Resignation Letter', url: '/resignation-letter' },
      { title: 'Last Will and Testament', url: '/last-will-and-testament' },
      {
        title: 'Personal Financial Statement',
        url: '/personal-financial-statement',
      },
      { title: 'Living Will', url: '/living-will' },
    ],
    type: 'Personal Legal Forms',
  },
  {
    description:
      'Small business legal forms are offered at FormSwift, including confidentiality agreements, bills of sales, service contracts, and loan agreements. Small business forms may involve two or more parties, and they should always include as much information as possible about the small business that is either being created or already exists. These legal documents can help a small business set up their new endeavor, whether it is purchasing real estate, setting up employee or contractor contracts, or acquiring loans. They are also useful for established small businesses that are performing important transactions.',
    links: [
      { title: 'Employee Handbook', url: '/employee-handbook' },
      { title: 'Employment Contract', url: '/employment-contract' },
      { title: 'Job Application', url: '/job-application' },
      { title: 'Invoice', url: '/invoice' },
      { title: 'Purchase Order', url: '/purchase-order' },
      { title: 'Non Disclosure Agreement', url: '/non-disclosure-agreement' },
      { title: 'Release Of Liability', url: '/release-of-liability' },
      { title: 'Non Compete Agreement', url: '/non-compete-agreement' },
      { title: 'Promissory Note', url: '/promissory-note' },
      { title: 'Business Plan', url: '/business-plan' },
    ],
    type: 'Small Business Legal Forms',
  },
  {
    description:
      'Many of the legal documents on FormSwift are related to real estate. These forms include property purchase agreements, deed contracts, leasing options, and mortgage transfers. These real estate forms are relevant to individuals, small businesses, and large companies. They may be used when purchasing a home or business locations, when transferring a deed from one party to another, or when finding funding for a real estate transaction.',
    links: [
      { title: 'Lease Agreement', url: '/lease-agreement' },
      { title: 'Sublease Agreement', url: '/sublease-agreement' },
      { title: 'Contract For Deed', url: '/contract-for-deed' },
      { title: 'Quit Claim Deed', url: '/quit-claim-deed' },
      { title: 'Eviction Notice', url: '/eviction-notice' },
      { title: 'Rental Application', url: '/rental-application' },
      {
        title: 'Month By Month Lease Agreement',
        url: '/month-to-month-lease-agreement',
      },
    ],
    type: 'Real Estate Legal Forms',
  },
  {
    description:
      'FormSwift also offers a complete suite of tax forms for businesses, including W2, 1099-NEC, 1099-MISC, 1099-INT, W9, and pay stubs. These tax forms can be filled out with our easy to use pdf editor, and are updated to the latest version ever year. FormSwift subscribers can also use our PDF editor tool to upload and edit their own documents with a single click. So far, more than 10 million documents have been created on FormSwift.',
    links: [
      { title: 'W2', url: '/w2' },
      { title: 'W9', url: '/w9' },
      { title: '1099-NEC', url: '/sem/visual-static/1099-nec' },
      { title: '1099-MISC', url: '/1099-misc' },
      { title: '1099-INT', url: '/1099-int' },
      { title: 'Pay Stub', url: '/pay-stub' },
      { title: 'Balance Sheet', url: '/balance-sheet' },
      { title: 'Edit Your PDF', url: '/edit-pdf' },
    ],
    type: 'Tax Forms',
  },
];

export const RESOURCES = [
  {
    description:
      'Comprehensive guide to understanding SWOT analysis and how to conduct one for your business.',
    hasImage: false,
    responsiveWidths: {
      md: 6,
      xl: 4,
      xs: 12,
    },
    title: 'An Essential Guide to SWOT Analysis',
    url: '/business-plan#swotanalysis',
  },
  {
    description:
      'Overview of the Gig Economy and how to be a successful freelancer in various sectors of the economy.',
    hasImage: false,
    responsiveWidths: {
      md: 6,
      xl: 4,
      xs: 12,
    },
    title: 'The Freelancer’s Guide to Business & Taxes',
    url: '/w9#guide',
  },
  {
    description:
      'In-depth review of what the IRS W2 Form is and how it is used by both employee and employer.',
    hasImage: false,
    responsiveWidths: {
      md: 6,
      xl: 4,
      xs: 12,
    },
    title: 'W2 Form Guide',
    url: '/w2',
  },
  {
    description:
      'Customizable lesson plan templates for a math, science, and language arts class.',
    hasImage: false,
    responsiveWidths: {
      md: 6,
      xl: 4,
      xs: 12,
    },
    title: 'Lesson Plans Hub',
    url: '/common-core-lesson-plans',
  },
  {
    description:
      'Easily customize, sign, and download popular business, legal, and personal forms.',
    hasImage: true,
    responsiveWidths: {
      md: 12,
      xl: 8,
      xs: 12,
    },
    title: 'Legal Forms Library',
    url: '/legal-forms',
  },
  {
    description:
      'Exemplary business plans across a variety of different businesses types.',
    hasImage: false,
    responsiveWidths: {
      md: 6,
      xl: 4,
      xs: 12,
    },
    title: 'Sample Business Plans',
    url: '/sample-business-plans',
  },
  {
    description:
      'In-depth review of what the IRS 1099 Form is and how it is used by both contractor and employer.',
    hasImage: false,
    responsiveWidths: {
      md: 6,
      xl: 4,
      xs: 12,
    },
    title: '1099 Form Guide',
    url: '/1099-misc#guide',
  },
  {
    description:
      'Create a professional-looking email signature for your educational, business, or personal email account.',
    hasImage: false,
    responsiveWidths: {
      md: 6,
      xl: 4,
      xs: 12,
    },
    title: 'Email Signature Tool',
    url: '/email-signature',
  },
];
