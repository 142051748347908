import Homepage from 'Homepage';
import { getCommonInitialProps, getIsSignedIn } from 'lib/dataFetchers';
import { checkForRedirect, handleRedirects } from 'lib/redirects';
import { CONDITIONS, REDIRECTS } from 'lib/redirects/constants';
import withFormSwiftProviders from 'lib/withFormSwiftProviders';

const { IS_SIGNED_IN } = CONDITIONS;

const { MY_DOCUMENTS } = REDIRECTS;

const redirects = [{ conditions: IS_SIGNED_IN, redirect: MY_DOCUMENTS }];

Homepage.getInitialProps = handleRedirects(async (ctx) => {
  const commonInitialProps = await getCommonInitialProps(ctx);

  const isSignedIn = getIsSignedIn(ctx);
  await checkForRedirect(ctx, redirects, { isSignedIn });

  return {
    ...commonInitialProps,
    title: 'Create Legal Documents',
  };
});

export default withFormSwiftProviders(Homepage);
