// eslint-disable-next-line import/no-default-export
export default class RedirectError {
  /**
   * The final redirect url. Should generally be built from `RedirectInfo`
   *  using `buildRedirectUrl`
   */
  redirectPath = '';

  constructor(data?: Record<string, unknown>) {
    Object.assign(this, data);
  }
}
