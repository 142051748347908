import React from 'react';
import PropTypes from 'prop-types';
import { ProductContent } from 'DesignSystem/Typography';
import Image from 'Image';
import styles from './SingleTestimonial.module.scss';

const SingleTestimonial = ({
  name,
  position,
  ratingSource,
  review,
  source,
}) => (
  <div className={styles['testimonial-container']}>
    <ProductContent className={styles.review} variation="small">
      {review}
    </ProductContent>
    <div className="user-container">
      <ProductContent variation="small">{name}</ProductContent>
      <ProductContent variation="small">{position}</ProductContent>
    </div>
    <div className={styles['rating-container']}>
      <Image
        alt={ratingSource}
        height="12"
        src={`/static/images/homepage/${ratingSource}.png`}
        width="60"
      />
      <ProductContent className={styles.source} italic variation="smallest">
        <a href="https://www.g2.com/">on {source}</a>
      </ProductContent>
    </div>
  </div>
);

SingleTestimonial.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  ratingSource: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SingleTestimonial;
