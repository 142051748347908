import React from 'react';
import { HeadingE, ProductContent } from 'DesignSystem/Typography';
import Image from 'Image';
import styles from './FreeResourcesCard.module.scss';

export interface FreeResourcesCardProps {
  description: string;
  hasImage: boolean;
  fireTracking: (action: string) => void;
  title: string;
  url: string;
}

const FreeResourcesCard = ({
  description,
  fireTracking,
  hasImage,
  title,
  url,
}: FreeResourcesCardProps): JSX.Element => {
  const containerStyles = hasImage ? 'image-card-element' : 'card-element';
  return (
    <div className={styles[containerStyles]}>
      {hasImage && (
        <Image
          alt="Free Resources Shelf"
          className={styles['shelf-image']}
          src="/static/images/homepage/free-resources-shelf.png"
        />
      )}
      <div>
        <HeadingE className={styles['card-title']}>
          <a href={url} onClick={() => fireTracking(title)}>
            {title}&nbsp;›
          </a>
        </HeadingE>
        <ProductContent className={styles['card-description']}>
          {description}
        </ProductContent>
      </div>
    </div>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreeResourcesCard;
