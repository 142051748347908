import React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { trackEvent } from 'Tracking/Actions';
import { Col, Container, Row } from 'react-bootstrap';
import SecondaryCTAColumn from './SecondaryCTAColumn';
import styles from './SecondaryCTASection.module.scss';

const SecondaryCTASection = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const redirectUser = (trackingCategory, redirectPage) => {
    dispatch(trackEvent(`${trackingCategory} | Homepage`, 'Homepage'));
    router.push(redirectPage);
  };

  return (
    <Container className={styles['secondary-cta-section']}>
      <Row className={styles['secondary-cta-row']}>
        <Col className={styles['create-document-section']} lg={6}>
          <SecondaryCTAColumn
            ctaOnClick={() =>
              redirectUser('Browse Templates', '/free-legal-forms')
            }
            ctaText="Browse Templates"
            dataTestid="browse-templates"
            heading="Create Documents"
            info="Fill out templates to get documents for legal, tax, business, and personal needs. Then download your completed document in Word or PDF."
          />
        </Col>
        <Col className={styles['upload-file-section']} lg={6}>
          <SecondaryCTAColumn
            ctaOnClick={() => redirectUser('Upload PDF', '/edit-pdf')}
            ctaText="Upload File to Edit"
            dataTestid="upload-file"
            heading="Edit PDFs Online"
            info="Add text and eSignatures, erase, redact, highlight, and more. Print or download your finished document as PDF or Word."
          />
        </Col>
      </Row>
    </Container>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default SecondaryCTASection;
