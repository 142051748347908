import React from 'react';
import { SearchBar, SearchProvider } from 'DesignSystem/SearchBar';
import { useDocumentSearch, useWindowSize } from 'hooks';
import styles from './index.module.scss';
import DocumentSearchResults from './DocumentSearchResults';

export const ICON_NAME = 'SearchIcon';
export const ICON_POSITION = 'input';
export const MAX_QUERY_RATE = 500;
export const DESKTOP_PLACEHOLDER = 'Search by name or keywords, like “lease”';
export const MOBILE_PLACEHOLDER = 'Search';

const BaseDocumentSearchBar = (): JSX.Element => {
  useDocumentSearch('Homepage');
  const { isMobile } = useWindowSize();
  const SEARCH_RESULTS_ID = 'homepage-document-search-results';
  return (
    <SearchBar
      ariaLabel="Search Documents"
      dataTestid="homepage-document-search"
      iconName={ICON_NAME}
      iconPosition={ICON_POSITION}
      placeholder={isMobile ? MOBILE_PLACEHOLDER : DESKTOP_PLACEHOLDER}
      searchResultsId={SEARCH_RESULTS_ID}
      showButton={false}
    >
      <DocumentSearchResults maxItems={6} searchResultsId={SEARCH_RESULTS_ID} />
    </SearchBar>
  );
};

const DocumentSearchBar = (): JSX.Element => (
  <SearchProvider debounceRate={MAX_QUERY_RATE}>
    <div className={styles['homepage-above-the-fold-document-search']}>
      <BaseDocumentSearchBar />
    </div>
  </SearchProvider>
);

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default DocumentSearchBar;
