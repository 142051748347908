import React from 'react';
import { useDispatch } from 'react-redux';
import { trackEvent } from 'Tracking/Actions';
import { AutoCompleteDropdown, useSearchStatus } from 'DesignSystem/SearchBar';
import { getAutoCompleteItems } from 'hooks/useDocumentSearch';
import {
  documentSearchQuery_documentSearch_documents as DocumentSearchResult,
  documentSearchQuery as DocumentSearchData,
} from 'hooks/types/documentSearchQuery';
import BrowseByCategory from './BrowseByCategory';

const DocumentSearchResults = ({
  maxItems,
  searchResultsId,
}: {
  maxItems?: number;
  searchResultsId: string;
}): JSX.Element | null => {
  const status = useSearchStatus();
  const dispatch = useDispatch();

  const trackAndRedirect = (item: DocumentSearchResult | null | undefined) => {
    if (item) {
      const { documentLabel, seoLink } = item;
      dispatch(trackEvent('Go to Search Result | Homepage', documentLabel));
      window.location.assign(seoLink || '');
    }
  };

  const getItems = maxItems
    ? (data?: DocumentSearchData) =>
        getAutoCompleteItems(data)?.slice(0, maxItems)
    : getAutoCompleteItems;

  switch (status) {
    case 'complete':
    case 'no_result':
      return (
        <AutoCompleteDropdown
          ariaLabel="Document Search Results"
          getItems={getItems}
          iconDisplay="hover"
          iconName="ArrowRightIcon"
          iconPosition="right"
          id={searchResultsId}
          noResultsMessage="No results. Try a different search term. You can search by document name or keywords."
          onSelect={trackAndRedirect}
          resultsRole="link"
        >
          <BrowseByCategory />
        </AutoCompleteDropdown>
      );
    default:
      return null;
  }
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default DocumentSearchResults;
