import { NextPageContext } from 'next';
import buildRedirectUrl from './buildRedirectUrl';
import RedirectError from './RedirectError';
import { Conditions, RedirectInfo, RedirectInfoCreator } from './types';

/* eslint-disable @typescript-eslint/ban-types */

type Dictionary = Record<string, unknown>;

function allConditionsAreSatisfied(state: object, conditions: object): boolean {
  const conditionKeys = Object.keys(conditions);

  // If your redirect tests are unexpectedly failing, it can be useful to print this.
  // Often this reveals that a state variable is not being loaded properly in
  // getInitialProps.
  // console.log('checking redirect', conditions, state);

  for (let j = 0; j < conditionKeys.length; j += 1) {
    const conditionKey = conditionKeys[j];
    if (
      (state as Dictionary)[conditionKey] !==
      (conditions as Dictionary)[conditionKey]
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Gets the pathname from the Next.js `ctx.asPath` parameter.
 * `ctx.pathname` does not work since it does not include dynamic route params.
 *
 * See: https://nextjs.org/docs/api-reference/data-fetching/getInitialProps#context-object
 */
export function getPathname(asPath: string): string {
  const url = new URL(asPath, 'https://formswift.com/');
  return url.pathname;
}

/** Object describing a potential redirect on a page and the conditions that trigger it.
 * See: {@link Conditions}, {@link RedirectInfo}, {@link RedirectInfoCreator}
 */
type Redirect = {
  conditions: Conditions;
  redirect: RedirectInfo | RedirectInfoCreator;
};

type AllowAdditionalProperties<O> = O extends object
  ? O & Record<string, unknown>
  : never;

/** Extracts the input type from `ConditionMap | ConditionFunc` union */
type InputTypeFromConditionsFunc<C> = C extends (args: infer I) => void
  ? I
  : C extends Record<string, unknown>
  ? {} // We only care about functional conditions, not object ones
  : never;

/** Extracts the input type from RedirectInfos */
type InputTypeFromRedirectInfoFunc<C> = C extends (
  state: infer I
) => RedirectInfo
  ? I
  : C extends RedirectInfo
  ? {} // We only care about functional RedirectInfos, not object ones
  : never;

type InputStateFromRedirect<R extends Redirect> = InputTypeFromConditionsFunc<
  R['conditions']
> &
  InputTypeFromRedirectInfoFunc<R['redirect']>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

/**
 * Checks if the user's state matches conditions for a redirect. If so, builds and
 * throws a RedirectError.
 *
 * See the Page-level Redirects notion page for more information.
 *
 * @param ctx The Next.js context object.
 * @param redirectsList List of {@link Redirect}s with their conditions.
 * @param state State variables like `isSignedIn` and `funnel`.
 */
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default function checkForRedirect<Redirects extends Redirect[]>(
  ctx: NextPageContext,
  redirectsList: Readonly<[...Redirects]>,
  state: AllowAdditionalProperties<
    UnionToIntersection<InputStateFromRedirect<Redirects[number]>>
  >
): void {
  const { asPath, query } = ctx;

  if (asPath === undefined) return;

  for (let i = 0; i < redirectsList.length; i += 1) {
    const { conditions, redirect } = redirectsList[i];
    const shouldRedirect =
      typeof conditions === 'function'
        ? conditions(state)
        : allConditionsAreSatisfied(state, conditions);

    if (shouldRedirect) {
      const redirectInfo =
        typeof redirect === 'function' ? redirect(state) : redirect;
      const pathname = getPathname(asPath);
      const redirectPath = buildRedirectUrl(pathname, redirectInfo, query);
      throw new RedirectError({ redirectPath });
    }
  }
}
/* eslint-enable @typescript-eslint/ban-types */
