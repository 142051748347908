import React from 'react';
import { AlternativeTertiaryButton } from 'DesignSystem/Buttons';
import { trackEvent } from 'Tracking/Actions';
import { useDispatch } from 'react-redux';
import styles from './BrowseByCategory.module.scss';

const BrowseByCategory = (): JSX.Element => {
  const dispatch = useDispatch();

  const trackAndScroll = () => {
    dispatch(trackEvent('Browse Category | Homepage', 'Homepage'));
    window.location.assign('#legalForms');
  };

  return (
    <>
      <style global jsx>
        {`
          html {
            scroll-behavior: smooth;
          }
        `}
      </style>
      <AlternativeTertiaryButton
        className={styles['browse-by-category-button']}
        dataTestid="browse-by-category"
        disabled={false}
        onClick={trackAndScroll}
        size="sm"
      >
        Browse By Category
      </AlternativeTertiaryButton>
    </>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default BrowseByCategory;
