import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'Image';
import { useWindowSize } from 'hooks';
import { StatusBanner } from 'StatusBanner/StatusBanner';
import DocumentSearchBar from './DocumentSearchBar';
import styles from './AboveTheFoldSection.module.scss';

const AboveTheFoldSection = (): JSX.Element => {
  const { isMinWidthLg } = useWindowSize();

  return (
    <div className={styles['homepage-above-the-fold-section-wrapper']}>
      <Container className={styles['homepage-above-the-fold-container']}>
        <div className={styles['homepage-above-the-fold-banner']}>
          <StatusBanner
            additionalBannerClass={styles['homepage-above-the-fold-status']}
          />
        </div>
        <Row>
          <Col lg={{ offset: 1, span: 10 }} xs={{ offset: 0, span: 12 }}>
            <h1 className={styles['homepage-above-the-fold-header']}>
              Create, Edit, and Sign Any Document
            </h1>
            <h2 className={styles['homepage-above-the-fold-sub-header']}>
              All your document needs, all in one place
            </h2>
            <DocumentSearchBar />
          </Col>
        </Row>
      </Container>
      <Image
        alt="Create, Edit, and Sign Any Document"
        className={styles['document-signature-img']}
        src={`/static/images/homepage/document-signature-illustration-${
          isMinWidthLg ? 'desktop' : 'mobile'
        }.png`}
      />
    </div>
  );
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default AboveTheFoldSection;
