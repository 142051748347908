import React from 'react';
import { HeadingB, ProductContent } from 'DesignSystem/Typography';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './FreeResourcesSection.module.scss';
import { RESOURCES } from './Constants';
import FreeResourcesCard from './FreeResourcesCard';

export interface FreeResourcesSectionProps {
  fireTracking: (action: string) => void;
}

const FreeResourcesSection = ({
  fireTracking,
}: FreeResourcesSectionProps): JSX.Element => {
  return (
    <div className={styles['free-resources-container']}>
      <div className={styles['text-container']}>
        <HeadingB>Free Resources</HeadingB>
        <ProductContent className={styles['section-description']}>
          Whether you&apos;re looking to optimize your business or download
          legal forms, we have something useful for you. Make sure to check back
          for new features, and tell us what you&apos;d like to see!
        </ProductContent>
      </div>
      <Container className={styles['free-resources-card-container']}>
        <Row>
          {RESOURCES.map(
            ({ description, hasImage, responsiveWidths, title, url }) => (
              <Col
                key={title}
                className={styles['free-resources-card']}
                md={responsiveWidths.md}
                xl={responsiveWidths.xl}
                xs={responsiveWidths.xs}
              >
                <FreeResourcesCard
                  description={description}
                  fireTracking={fireTracking}
                  hasImage={hasImage}
                  title={title}
                  url={url}
                />
              </Col>
            )
          )}
        </Row>
      </Container>
    </div>
  );
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default FreeResourcesSection;
