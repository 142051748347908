import React from 'react';
import Image from 'Image';
import styles from './PublicationSources.module.scss';

const PublicationSources = () => (
  <div className={styles['sources-container']}>
    <div className={styles['first-half-container']}>
      <Image
        alt="The New York Times"
        height="22.75"
        src="/static/images/press/nyt-logo-grey.png"
        width="162"
      />
      <Image
        alt="The Wall Street Journal"
        height="16.93"
        src="/static/images/press/wsj-logo-grey.png"
        width="192"
      />
      <Image
        alt="The Washington Post"
        height="25.53"
        src="/static/images/press/washingtonpost-logo-grey.png"
        width="164"
      />
    </div>
    <div className={styles['last-half-container']}>
      <Image
        alt="Huffpost"
        height="14.58"
        src="/static/images/press/huffpost-logo-grey.png"
        width="125"
      />
      <Image
        alt="Inc."
        height="17.42"
        src="/static/images/press/inc-logo-grey.png"
        width="50"
      />
      <Image
        alt="TNW"
        height="13.93"
        src="/static/images/press/tnw-logo-grey.png"
        width="57"
      />
    </div>
  </div>
);
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default PublicationSources;
