import { ErrorMessageConstants } from 'ErrorMessage/ErrorMessageUtils';
import { Conditions } from './types';

/** @deprecated Conditions and redirects should be set separately */
export const SIGN_IN_PAGE_REDIRECT = {
  conditions: { isSignedIn: false },
  redirect: { path: '/sign-in', redirectBack: true },
};

interface Funnel {
  funnel: 'static' | 'esignature' | 'fax' | 'freewrite';
}

interface CheckoutError {
  checkoutError: string;
}

interface Country {
  countryIsoAlpha2: string;
}

interface Credits {
  credits: number;
}

interface HasPaidAccess {
  hasPaidAccess: boolean;
}

interface IsSignedIn {
  isSignedIn: boolean;
}

interface IsFreemium {
  isFreemium: boolean;
}

interface FaxNumber {
  faxNumber: string;
}
interface SessionIdAndHash {
  sessionId?: number;
  sessionHash?: string;
}

export const CONDITIONS = {
  CHECKOUT_LOGGED_OUT: ({
    checkoutError,
    isSignedIn,
  }: CheckoutError & IsSignedIn) =>
    !isSignedIn || checkoutError === ErrorMessageConstants.CHECKOUT_LOGGED_OUT,
  DOCUMENT_NOT_FOUND_ERROR: { gqlError: 'DocumentNotFoundGqlError' },

  FAX_NUMBER_DOES_NOT_EXIST: { faxNumber: undefined },
  FAX_NUMBER_EXISTS: ({ faxNumber }: FaxNumber) => !!faxNumber,
  FLOWCHART: { documentType: 'flowchart' },
  FUNNEL_ESIGN: { funnel: 'esignature' },
  FUNNEL_FAX: { funnel: 'fax' },
  FUNNEL_FREEMIUM: ({ funnel, isFreemium }: Funnel & IsFreemium) =>
    isFreemium && funnel !== 'esignature',
  FUNNEL_FREEWRITE: { funnel: 'freewrite' },
  FUNNEL_NOT_FAX: ({ funnel }: Funnel) => funnel && funnel !== 'fax',
  FUNNEL_STATIC: { funnel: 'static' },
  HAS_CREDITS: ({ credits }: Credits) => credits > 0,
  HAS_NO_SUBSCRIPTION: { hasSubscription: false }, // Does the user not have a Chargify subscription in any state
  ID_AND_HASH_EXISTS: ({ sessionHash, sessionId }: SessionIdAndHash) =>
    !!(sessionId && sessionHash),
  ID_OR_HASH_DO_NOT_EXIST: ({ sessionHash, sessionId }: SessionIdAndHash) =>
    !sessionId || !sessionHash,

  INVALID_PERMISSIONS_ERROR: { gqlError: 'InvalidPermissionsGqlError' },
  INVALID_SESSION_ERROR: { gqlError: 'InvalidSessionGqlError' },
  IS_CANCELLED: { isCancelled: true },
  IS_NON_US_COUNTRY: ({ countryIsoAlpha2 }: Country) =>
    countryIsoAlpha2 && countryIsoAlpha2 !== 'US',
  IS_NOT_FREEMIUM: { isFreemium: false },
  IS_NOT_PAID_NOR_CREDITS: ({
    credits,
    hasPaidAccess,
  }: Credits & HasPaidAccess) => !hasPaidAccess && credits === 0,
  IS_PAID: { hasPaidAccess: true },
  IS_SIGNED_IN: { isSignedIn: true },
  IS_SUBSCRIBER: { isCurrentSubscriber: true }, // Neither this nor IS_PAID really say whether the user is a subscriber. They say whether the user currently has paid access.
  NOT_SIGNED_IN: { isSignedIn: false },
  USER_REQUIRED_ERROR: { gqlError: 'UserRequiredGqlError' },
} as const;

/**
 * DO NOT USE. This export is purely for type checking purposes.
 * If this declaration has a typescript error, that means
 * one of the conditions has an invalid signature.
 */
export const EnsureConditionsAreWellFormed: {
  [key: string]: Conditions;
} = CONDITIONS;

export const REDIRECTS = {
  APPLY_ESIGN_PROMO_CODE: {
    path: '/applyPromoCode.php?code=ESIGN&redirect_url=my-documents',
  },
  BUILDER: {
    FREEWRITE: {
      path: '/builder/freewrite',
    },
    SHARED: {
      path: `/builder/shared`,
    },
    SHARED_KSL: {
      path: `/free-write/edit-shared.php`,
    },
    STATIC: {
      path: '/builder/static',
    },
  },

  CHECKOUT_ESIGN: { path: '/checkout-esign' },
  CHECKOUT_FAX: { path: '/checkout-fax' },
  CHECKOUT_FREEWRITE: { path: '/checkout-fw' },
  CHECKOUT_STATIC: { path: '/checkout' },

  CHOOSE_FAX_NUMBER: { path: '/choose-fax-number' },

  CONFIRMATION_ESIGN: { path: '/confirmation-esign.php' },
  CONFIRMATION_FAX: { path: '/confirmation-fax' },
  CONFIRMATION_FREEWRITE: { path: '/confirmation-fw' },
  CONFIRMATION_STATIC: { path: '/confirmation' },

  HOMEPAGE: { path: '/' },
  MY_ACCOUNT: { path: '/my-account' },
  MY_DOCUMENTS: { path: '/my-documents' },
  MY_FAXES: { path: '/my-faxes' },
  SIGN_IN: { path: '/sign-in', redirectBack: true },
  SIGN_IN_WITH_ERROR: {
    path: '/sign-in?error=restricted-access',
    redirectBack: false,
  },

  UPGRADE_PRICING_ESIGN: { path: '/upgrade-pricing-esign' },
  UPGRADE_PRICING_FAX: { path: '/upgrade-pricing-fax' },
  UPGRADE_PRICING_FREEMIUM: {
    omitQueryParams: ['url'],
    path: '/upgrade-freemium',
  },
  UPGRADE_PRICING_FREEWRITE: { path: '/upgrade-pricing-fw' },
  UPGRADE_PRICING_STATIC: { path: '/upgrade-pricing' },
};
