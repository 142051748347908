import React from 'react';
import { REVIEWS } from './Constants';
import styles from './TestimonialsSection.module.scss';
import SingleTestimonial from './SingleTestimonial';

const TestimonialSection = () => (
  <div className={styles['testimonials-container']}>
    {REVIEWS.map((r) => (
      <SingleTestimonial
        key={r.name}
        name={r.name}
        position={r.position}
        ratingSource={r.ratingSource}
        review={r.review}
        source={r.source}
      />
    ))}
  </div>
);
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default TestimonialSection;
